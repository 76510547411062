// TODO this file could go into lib/services
//  - but do we want the analytics package as a peerDep there?
//  - is this really ever used as it is?

import {
  checkoutFinishedEvent,
  checkoutStartedEvent,
  checkoutStepEvent,
  itemDetailEvent,
  itemListEvent,
  routeChangeEvent,
  LoggerFactory,
  addTravelDetailsEvent,
  addAdditionalServicesEvent
} from '@ibe/services';
import { tracking, trackingService } from './setup';
import { productItemListEvent } from './events';
import { ApiBestPricePackageModel } from '@ibe/api';

const logger = LoggerFactory.get('TrackingSubscriptions');

const trackingSubscriptions = (): void => {
  routeChangeEvent.subscribe(() => {
    logger.log('routeChangeEvent');
    tracking.page();
  });

  itemListEvent.subscribe(payload => {
    logger.log('packageItemListEvent', payload);
    trackingService.trackPackageListView(
      payload.items as ApiBestPricePackageModel[],
      'view_item_list'
    );
  });

  productItemListEvent.subscribe(payload => {
    logger.log('productItemListEvent', payload);
    trackingService.trackProductListView(payload, 'view_item_list');
  });

  itemDetailEvent.subscribe(payload => {
    logger.log('itemDetailEvent', payload);
    trackingService.trackItem(payload.item, 'view_item');
  });

  checkoutStartedEvent.subscribe(payload => {
    logger.log('beginCheckoutEvent', payload);
    trackingService.beginCheckout(payload.booking, 'begin_checkout');
  });

  checkoutStepEvent.subscribe(payload => {
    logger.log('checkoutStepEvent', payload);
    trackingService.checkoutStep('checkout_step');
  });

  addTravelDetailsEvent.subscribe(payload => {
    logger.log('addTravelDetailsEvent', payload);
    trackingService.addTravelDetails(
      payload.booking,
      'add_travel_details',
      payload.step,
      payload.hotel,
      payload.amountRooms,
      payload.mealDescription,
      payload.duration
    );
  });

  addAdditionalServicesEvent.subscribe(payload => {
    logger.log('addAdditionalServicesEvent', payload);
    trackingService.additionalServices(payload.name, 'add_additional_services');
  });

  checkoutFinishedEvent.subscribe(payload => {
    logger.log('purchaseEvent', payload);
    trackingService.purchase(payload.booking, 'purchase');
  });
};
export default trackingSubscriptions;
