import { SessionStorage } from '@ibe/services';
import {
  ApiBookedItem,
  ApiDurationInfo,
  ApiDurationType,
  ApiImageContainer,
  ApiItemType,
  ApiPriceModifierType,
  ApiTraveler
} from '@ibe/api';
import { ProductIBEMode } from '../Checkout/CheckoutStore';

export const PACKAGE_ID_KEY = 'thg-package-id';

export const packageIdSessionStorage = new SessionStorage<{
  packageId: string;
  packageCode: string;
  numberOfTravelers: number;
}>(PACKAGE_ID_KEY);

export const VALID_PROMOTION_CODE_DATA = 'thg-valid-promotion-code-data';

const PRODUCT_DETAILS_PAGE_URL = 'thg-product-details-page-url';

const WHITELABEL_SEASON = 'thg-whitelabel-season';

const TRAVELERS_SAME_ADDRESS_FORMS = 'thg-travelers-same-address-forms';

const PRODUCT_IBE_MODE = 'thg-product-ibe-mode';

export type ValidPromotionCode = {
  code: string;
  percentageValue?: number;
  absoluteValue?: number;
  preSign?: string;
  sign?: string;
  manuallyAdded?: boolean;
  productCode?: string;
};

export type SeasonHotelInformation = {
  code: string;
  productName: string;
  dateFrom: Date;
  dateTo: Date;
  additionalServices: string;
  smallGroup: boolean;
  codeForSmallGroup: string;
  image?: ApiImageContainer;
  icon?: ApiImageContainer;
  seasonCountry?: {
    countryName: string;
  };
};

export const travelersSameAddressForms = new SessionStorage<ApiTraveler[]>(
  TRAVELERS_SAME_ADDRESS_FORMS
);

export const productIbeModeStorage = new SessionStorage<ProductIBEMode>(PRODUCT_IBE_MODE);

export const validPromotionCodeStorage = new SessionStorage<ValidPromotionCode>(
  VALID_PROMOTION_CODE_DATA
);

export const productDetailsPageUrlStorage = new SessionStorage<string>(PRODUCT_DETAILS_PAGE_URL);

export const whitelabelSeasonStorage = new SessionStorage<SeasonHotelInformation>(
  WHITELABEL_SEASON
);

export const clearStorage = (): void => {
  productDetailsPageUrlStorage.clear();
};

export const getTotalDiscount = (
  travelers: ApiTraveler[],
  bookedItems?: ApiBookedItem[] | null
): number => {
  const services = bookedItems?.filter(service => service.itemType !== ApiItemType.PACKAGE);
  const discountMap = new Map<string, number>();
  travelers.forEach(traveler => {
    discountMap.set(traveler.id, 0);
    services?.forEach(current => {
      if (
        current.priceByPersonId &&
        current.priceByPersonId[traveler.id] &&
        current.priceByPersonId[traveler.id].modifiers
      ) {
        const discount = current.priceByPersonId[traveler.id].modifiers.find(
          modifier => modifier.type === ApiPriceModifierType.DISCOUNT
        );
        if (discount) {
          discountMap.set(
            traveler.id,
            discountMap.get(traveler.id) || 0 + Math.abs(discount.absolute)
          );
        }
      }
    });
  });
  return Math.max(...discountMap.values());
};

export const formatPrice = (
  amount: number // 1234.23
): string => {
  const numberFormat = new Intl.NumberFormat('de-DE', { style: 'decimal', useGrouping: false });
  return `${amount < 0 ? '- ' : ''}€ ${
    amount < 0 ? Math.abs(parseInt(numberFormat.format(amount), 10)) : numberFormat.format(amount)
  }`;
};

export const computeDuration = (durationInfo: ApiDurationInfo): number => {
  return durationInfo.type === ApiDurationType.NIGHT
    ? durationInfo.duration + 1
    : durationInfo.duration;
};
