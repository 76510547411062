import { ApiProductCard } from '../../api';
import ProductItemListEvent from './productItemListEvent';
import {
  ApiBestPricePackageModel,
  ApiBooking,
  ApiCustomer,
  ApiItem,
  ApiItemType,
  ApiPriceModifierType
} from '@ibe/api';
import { PackageModelId } from '@ibe/components';
import dayjs from 'dayjs';

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, unknown>[];
};

export enum StepNames {
  DURATION_SELECTION = 'Duration selection',
  UPDATE_TRAVELLERS = 'Update travellers',
  HOTEL_SELECTION = 'Hotel selection',
  ROOM_SELECTION = 'Room selection',
  MEAL_SELECTION = 'Meal selection'
}

declare const window: WindowWithDataLayer;

class TrackingService {
  private readonly itemBrandName = 'trendtours';

  private readonly affiliationName = this.itemBrandName + ' Online Store';

  private readonly searchResultPageName = 'Suchergebnisseite';

  private readonly daysStr = ' Tage';

  private readonly travelYearStr = 'Reisejahr ';

  trackProductListView(payload: ProductItemListEvent, eventName: string): void {
    const items = this.mapProductsToItems(payload.items);
    const data: Ecommerce = {
      item_list_id: this.searchResultPageName.toLowerCase(),
      item_list_name: this.searchResultPageName,
      items
    };
    this.pushToWindowDataLayer(eventName, data);
  }

  trackPackageListView(payload: ApiBestPricePackageModel[], eventName: string): void {
    const items: Item[] = [];
    payload.forEach((it, index) => {
      const item: Item = this.mapItem(it, index);
      item.item_list_id = this.searchResultPageName.toLowerCase();
      item.item_list_name = this.searchResultPageName;
      items.push(item);
    });
    const data: Ecommerce = {
      item_list_id: this.searchResultPageName.toLowerCase(),
      item_list_name: this.searchResultPageName,
      items
    };
    this.pushToWindowDataLayer(eventName, data);
  }

  trackItem(payload: ApiItem, eventName: string): void {
    const item = this.mapItem(payload);
    const data: Ecommerce = {
      currency: payload.price.currencyCode,
      value: payload.price.finalPrice,
      items: [item]
    };
    this.pushToWindowDataLayer(eventName, data);
  }

  beginCheckout(payload: ApiBooking | null, eventName: string): void {
    if (payload?.bookedItems) {
      const data = this.mapBookedItems(payload);
      this.pushToWindowDataLayer(eventName, data);
    }
  }

  addTravelDetails(
    payload: ApiBooking | null,
    eventName: string,
    step: string | null,
    hotel?: string | null,
    amountRooms?: number | null,
    meals?: string | null,
    duration?: number | null
  ): void {
    if (payload?.bookedItems) {
      const translatedStepName = this.getTranslatedStepName(step);
      const existingEcommerce = this.getFromWindowDataLayer('add_travel_details');
      if (!existingEcommerce) {
        const data = this.mapBookedItems(payload);
        const travelDetails = this.mapTravelDetails(
          payload,
          step,
          hotel,
          amountRooms,
          meals,
          duration
        );
        data.items[0] = { ...data.items[0], ...travelDetails };
        this.pushToWindowDataLayer(eventName, data, translatedStepName);
      } else {
        const travelDetails = this.mapTravelDetails(
          payload,
          step,
          hotel,
          amountRooms,
          meals,
          duration
        );
        existingEcommerce.items[0] = { ...existingEcommerce.items[0], ...travelDetails };
        this.pushToWindowDataLayer(eventName, existingEcommerce, translatedStepName);
      }
    }
  }

  checkoutStep(eventName: string): void {
    const data = this.getFromWindowDataLayer('add_travel_details');
    if (data) {
      const activeStep = document.getElementsByClassName(
        'iso__imageProgressBar__stepperItem__stepName--active'
      );
      const step = activeStep[0]?.innerHTML;
      this.pushToWindowDataLayer(eventName, data, step);
    }
  }

  additionalServices(serviceName: string | null, eventName: string): void {
    const existingEcommerce = this.getFromWindowDataLayer('add_travel_details');
    if (existingEcommerce) {
      const existingItem = [...existingEcommerce.items][0];
      const additionalServices = existingItem.additional_services
        ? existingItem.additional_services
        : [];
      if (serviceName) {
        additionalServices.push(serviceName);
      }
      existingItem.additional_services = additionalServices;
      const data = { ...existingEcommerce, items: [existingItem] };
      this.pushToWindowDataLayer(eventName, data);
    }
  }

  purchase(payload: ApiBooking | null, eventName: string): void {
    const existingEcommerce = this.getFromWindowDataLayer('add_additional_services');
    if (payload && existingEcommerce) {
      const tax = payload.price.modifiers.find(pr => pr.type === ApiPriceModifierType.TAX)
        ?.absolute;
      existingEcommerce.transaction_id = +payload.bookingNumber;
      existingEcommerce.shipping = 0; // No shipping whatsoever
      existingEcommerce.tax = tax;

      const client = payload?.client as ApiCustomer;
      existingEcommerce.first_name = client.firstName;
      existingEcommerce.last_name = client.lastName;
      existingEcommerce.postal_code = client.address.postalCode;
      existingEcommerce.country = client.address.countryCode;
      existingEcommerce.email = client.communicationDetails.email;
      existingEcommerce.phone = client.communicationDetails.phone;
      this.pushToWindowDataLayer(eventName, existingEcommerce);
    }
  }

  private getItemCategory(typeCode?: string): string {
    switch (typeCode) {
      case 'SB':
        return 'Sonnenhits';
      case 'PFLUG':
        return 'Flugreise';
      default:
        return '';
    }
  }

  private mapItem(packageItem: Partial<ApiBestPricePackageModel>, index = 0): Item {
    const code = packageItem.code;
    const name = packageItem.description ? packageItem.description : packageItem.name;

    // TODO: Check price & discount
    const priceModifiers = packageItem.price?.modifiers?.find(
      pr => pr.type === ApiPriceModifierType.DISCOUNT
    );
    let coupon = '';
    if (packageItem?.id) {
      const decodeId = JSON.parse(atob(packageItem.id));
      coupon = decodeId.promotionCode ? decodeId.promotionCode : '';
    }
    let discount = priceModifiers?.absolute;
    discount = discount ? Math.abs(discount) : 0;

    const itemCategory = this.getItemCategory(packageItem.typeCode);
    const itemCategory2 = packageItem?.geoAssignment?.geoUnit?.name;
    const endDate = dayjs(packageItem.endDate);
    const itemCategory3 = this.travelYearStr + endDate.year();

    // TODO: check if shown duration matches the selected package (e.g.: '15-Tägige Reise' --> 15)
    const duration = packageItem.duration?.duration + this.daysStr;
    const price = packageItem.price?.finalPrice;

    return {
      item_id: code,
      item_name: name,
      affiliation: this.affiliationName,
      coupon: coupon,
      discount: discount,
      index: index,
      item_brand: this.itemBrandName,
      item_category: itemCategory,
      item_category2: itemCategory2,
      item_category3: itemCategory3,
      item_variant: duration,
      price: price,
      quantity: 1
    };
  }

  private mapBookedItems(payload: ApiBooking): Ecommerce {
    const bookedItems = payload.bookedItems;
    const packageItem = bookedItems.find(it => it.itemType === ApiItemType.PACKAGE);
    let itemId;
    let itemName;
    let itemCategory;
    let variant;
    let travelStartDate;
    const coupon = payload.promoCodes[0];
    if (packageItem) {
      const decodedPackageId = packageItem.id ? JSON.parse(atob(packageItem.id)) : undefined;
      let decodedPackageModelId: Partial<PackageModelId>;
      itemName = packageItem.name;
      if (!!decodedPackageId) {
        decodedPackageModelId = JSON.parse(atob(decodedPackageId.packageModelId));
        const typeCode = decodedPackageModelId.typeCode;
        itemCategory = this.getItemCategory(typeCode);
        itemId = decodedPackageModelId?.code;
      }
      const endDate = dayjs(packageItem.endDate);
      variant = this.travelYearStr + endDate.year();
      travelStartDate = packageItem.startDate;
    }

    const flightItem = bookedItems.find(it => it.itemType === ApiItemType.FLIGHT);
    let flightDepartureAirport;
    if (flightItem && flightItem.idParent) {
      const decodedIdParent = JSON.parse(atob(flightItem.idParent));
      const decodedIdParentId = JSON.parse(atob(decodedIdParent.id));
      flightDepartureAirport = decodedIdParentId.startingPointCode;
    }

    let discount = payload?.totalDiscount?.finalPrice;
    discount = discount ? Math.abs(discount) : 0;

    const item: Item = {
      item_id: itemId,
      item_name: itemName,
      affiliation: this.affiliationName,
      coupon: coupon,
      discount: discount,
      index: 0,
      item_brand: this.itemBrandName,
      item_category: itemCategory,
      item_list_id: payload?.id,
      item_list_name: this.searchResultPageName,
      item_variant: variant,
      price: payload?.price.finalPrice,
      quantity: 1,
      flight_abflughafen: flightDepartureAirport,
      travel_start_date: travelStartDate,
      amount_travellers: payload?.travelers.length
    };

    const currency = payload.price.currencyCode;
    const value = payload.price.finalPrice;

    return {
      value: value,
      currency: currency,
      checkout_type: 'iso',
      coupon: coupon,
      items: [item]
    };
  }

  private mapProductsToItems(payload: ApiProductCard[]): Array<Item> {
    return payload.map((card, index) => {
      const price =
        card.strikeThroughPrice && card.strikeThroughPrice.amount
          ? card.strikeThroughPrice
          : card.price;

      const name = this.replaceHtmlAndUmlauts(card.name);
      const seasonName = this.replaceHtmlAndUmlauts(card.seasonName);

      const item: Item = {
        item_id: card.code,
        item_name: name,
        affiliation: this.affiliationName,
        coupon: card.selectedPromotionCode || '',
        discount: card.selectedPromotionCode ? parseFloat(card.selectedPromotionValue) : '',
        index,
        item_brand: this.itemBrandName,
        item_category: card.productTypes.join(', '),
        item_category2: card.country.name,
        item_category3: seasonName || '',
        price: price.amount,
        quantity: 1,
        item_list_id: this.searchResultPageName.toLowerCase(),
        item_list_name: this.searchResultPageName
      };
      return item;
    });
  }

  private pushToWindowDataLayer(eventName: string, data: Ecommerce, step?: string | null): void {
    const existingEventIndex = window.dataLayer.findIndex(event => event.event === eventName);
    if (existingEventIndex > -1) {
      window.dataLayer[existingEventIndex] = {
        ...window.dataLayer[existingEventIndex],
        ecommerce: { ...data }
      };
      window.dataLayer[existingEventIndex].step = step;
    } else {
      window.dataLayer.push({ event: eventName, step: step, ecommerce: { ...data } });
    }
  }

  private getFromWindowDataLayer(eventName: string): Ecommerce | undefined {
    const existingEventIndex = window.dataLayer.findIndex(event => event.event === eventName);
    if (existingEventIndex > -1) {
      const existingArray = window.dataLayer[existingEventIndex]?.ecommerce as Ecommerce;
      if (Array.isArray(existingArray.items)) {
        return {
          value: existingArray.value,
          currency: existingArray.currency,
          checkout_type: existingArray.checkout_type,
          coupon: existingArray.coupon,
          items: [...existingArray.items]
        };
      }
    }
  }

  private replaceHtmlAndUmlauts(input: string) {
    if (!input) {
      return input;
    }
    let output = this.clearHTMLTags(input);
    output = output.replaceAll('&auml;', 'ä');
    output = output.replaceAll('&Auml;', 'Ä');
    output = output.replaceAll('&ouml;', 'ö');
    output = output.replaceAll('&Ouml;', 'Ö');
    output = output.replaceAll('&uuml;', 'ü');
    output = output.replaceAll('&Uuml;', 'Ü');
    return output.trim();
  }

  private clearHTMLTags = (input: string) => {
    const output = new DOMParser().parseFromString(input, 'text/html');
    return output.body.textContent || '';
  };

  private mapTravelDetails(
    payload: ApiBooking,
    step: string | null,
    hotel?: string | null,
    amountRooms?: number | null,
    meals?: string | null,
    duration?: number | null
  ): Item {
    switch (step) {
      case StepNames.DURATION_SELECTION:
        const travelStartDate = payload.bookedItems[0].startDate;
        const travelEndDate = payload.bookedItems[0].endDate;
        const endDate = dayjs(travelEndDate);
        const startDate = dayjs(travelStartDate);
        const travelDuration = endDate.diff(startDate, 'day');
        return {
          travel_end_date: travelEndDate,
          travel_duration: duration ? duration : travelDuration
        };
      case StepNames.HOTEL_SELECTION:
        return {
          hotel: hotel != null ? hotel : ''
        };
      case StepNames.ROOM_SELECTION:
        return {
          amount_rooms: amountRooms != null ? amountRooms : 0
        };
      case StepNames.MEAL_SELECTION:
        return {
          hotel_meals: meals != null ? meals : ''
        };
      default:
        return {};
    }
  }

  private getTranslatedStepName(step: string | null): string | null {
    if (step === null) return null;
    const stepIndexMap: Record<StepNames, number> = {
      [StepNames.DURATION_SELECTION]: 0,
      [StepNames.UPDATE_TRAVELLERS]: 1,
      [StepNames.HOTEL_SELECTION]: 2,
      [StepNames.ROOM_SELECTION]: 3,
      [StepNames.MEAL_SELECTION]: 4
    };
    if (!(step in stepIndexMap)) {
      return step;
    }
    const wrapperIndex = stepIndexMap[step as StepNames] ?? 0;
    const wrapperElement = document.querySelector(
      `div[name="extended-booking-wrapper-${wrapperIndex}"] .extended__wrapper__left__title`
    );
    return wrapperElement?.textContent?.split('.')[1]?.trim() ?? step;
  }
}

export default TrackingService;
